
footer {

    font-size: .93rem;

    p, ul, ol {
        font-size: inherit;
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: inherit;
        &:hover {
            color: $primary-color;
        }
    }
    
    .social-media a:hover{
        color: #fff;
    }

    div > h3,
    div > h4 {
        margin-top: 0;
    }

    .info {

        color: #fff;
        background: #333333;
        padding-top: 90px;
        position: relative;

        &:after {
            pointer-events: none;
            left: 0;
            right: 0;
            content: "";
            position: absolute;
        }

        &:after {
            height: 100px;
            top: -1px;
            background: url(../img/cover1.png) repeat-x top center;
        }
    }

    .legal {

        .trading {

            @include breakpoint(medium) {
                text-align: right;
            }

            .cc {
                display: block;
                .icon {
                    font-size: 2rem;
                }
            }
        }
    }

}
