@include breakpoint(xlarge) {

    body {
        background: url('../img/bg-pattern.jpg') center top;
    }

    .body-wrapper {
        overflow: hidden;
        background: #fff;
        min-height: 100vh;
        box-shadow: 0 0 40px rgba(#000, .2);
    }
}

main {

    margin-bottom: 20px;
    min-height:200px;
    
    @include breakpoint(large) {
        margin-bottom: 40px;
    }

    h1.page-title {
        @include breakpoint(medium down) {
            margin-top: .66em;
        }
        &.has-children {
            margin-bottom: .9em;
        }
    }

    h2.page-title {
        @include breakpoint(large) {
            margin-top: -8px;
        }
    }
}

aside {

    margin-bottom: 40px;

    ul {
        font-family: $header-font-family;
        font-size: inherit;
    }
    
    & > .menu {
        margin-top: -5px;
        @each $size in $breakpoint-classes {
            $spacing: map-get($grid-margin-gutters, $size);
            $lower-bound-size: map-get($breakpoints, $size);
            $lower-bound: -zf-bp-to-em($lower-bound-size);
            @media screen and (min-width: $lower-bound) {
                margin-left: -$spacing/2;
                margin-right: -$spacing/2;
                li a {
                    padding-left: $spacing/2;
                    padding-right: $spacing/2;
                }
            }
        }
    }
}

article.has-aside {
    position: relative;
    &:after {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #fff;
        position: absolute;
        content: '';
        z-index: 0;
    }
    &:before {
        left: 0;
        top:50px;
        bottom:50px;
        width: 100px;
        position: absolute;
        content: '';
        box-shadow: 0 0 50px rgba(#000,.15);
        z-index: 0;
    }
    .article-wrapper {
        position: relative;
        z-index: 1;
    }
}

.social-media {
    display: inline-block;
    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        line-height: 1;

        margin-right: .33em;

        background-color: $secondary-color;
        height: 1.66em;
        width: 1.66em;
        font-size: 1.2em;
        color: #fff;
        border-radius: 100%;

        &.facebook{
            background-color: #3b5998!important;
        }
        &.youtube{
            background-color: #cc181e!important;
        }
        &.instagram{
            background-color: #d62976!important;
        }
        &.twitter{
            background-color: #1da1f2!important;
        }
        &.linkedin{
            background-color: #0077b5!important;
        }
        &.pinterest{
            background-color: #bd081c!important;
        }
        &.tripadvisor{
            background-color: #00aa83!important;
        }

        // uncomment for borders
        //&:after{
        //    content: '';
        //    display: block;
        //    position: absolute;
        //    height: calc(100% - 4px);
        //    width: calc(100% - 4px);
        //    border: #fff 1px solid;
        //    border-radius: 100%;
        //}

        &:hover {
            background-color: $primary-color!important;
        }

    }
}

.button {

    &.color-Purple {
        background: $mtzwp-purple;
    }
    &.color-Blue {
        background: $mtzwp-blue;
    }
    &.color-Ochre{
        background: $mtzwp-ochre;
    }
    &.color-Green {
        background: $primary-color;
        &:hover {
            background: $secondary-color;
        }
    }
    &:hover {
        background: $primary-color;
    }
}


.color-Purple a:not(.button) {
    color: $mtzwp-purple;
    &:hover {
        color: $primary-color;
    }
}
.color-Blue a:not(.button) {
    color: $mtzwp-blue;
    &:hover {
        color: $primary-color;
    }
}
.color-Ochre a:not(.button) {
    color: $mtzwp-ochre;
    &:hover {
        color: $primary-color;
    }
}


.color-Purple .button {
    background: $mtzwp-purple;
    &:hover {
        background: $primary-color;
    }
}
.color-Blue .button {
    background: $mtzwp-blue;
    &:hover {
        background: $primary-color;
    }
}
.color-Ochre .button {
    background: $mtzwp-ochre;
    &:hover {
        background: $primary-color;
    }
}
.color-Green .button {
    background: $primary-color;
    &:hover {
        background: $secondary-color;
    }
}
