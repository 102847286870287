@font-face {
    font-family: 'Chaparral Pro';
    src: url('../fonts/hinted-ChaparralPro-Bold.woff2') format('woff2'),
    url('../fonts/hinted-ChaparralPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Chaparral Pro';
    src: url('../fonts/hinted-ChaparralPro-Regular.woff2') format('woff2'),
    url('../fonts/hinted-ChaparralPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$mtzwp-blue: #439CC9;
$mtzwp-green: #1C713C;
$mtzwp-ochre: #B85133;
$mtzwp-purple: #755E90;

@import "_settings";

$lg-path-fonts: '../../node_modules/lightgallery/src/fonts';
$lg-path-images: '../../node_modules/lightgallery/src/img';
$slick-arrow-color: $primary-color !default;

@import "../../node_modules/foundation-sites/scss/foundation";
@import "../../node_modules/lightgallery/src/sass/lightgallery";
@import "../../node_modules/slick-carousel/slick/slick";

@import "_defaults";
@import "components/_content";
@import "components/_header";
@import "components/_banner";
@import "components/_homepage";
@import "components/_main";
@import "components/_footer";
