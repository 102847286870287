$print-hrefs: false;

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
@include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: none; /* browsers rarely hyphenate properly */
}

.body-wrapper {
    overflow: hidden;
}

::-moz-selection {
    background: #94bef1;
    text-shadow: none;
    color: rgba(0,0,0,.66);
}

::selection {
    background: #94bef1;
    text-shadow: none;
    color: rgba(0,0,0,.66);
}


$accessibility-highlight-colour: $secondary-color;

@mixin accessibility-highlight() {
    outline-width: 2px !important;
    outline-style: dashed !important;
    outline-color: #000;
    box-shadow: 0 0 2px $accessibility-highlight-colour, 0 0 6px $accessibility-highlight-colour, 0 0 10px $accessibility-highlight-colour, 0 0 15px $accessibility-highlight-colour !important;
    z-index: 1;
}

html[data-whatintent=keyboard] {
    a[href],
    area[href],
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled]),
    button:not([disabled]),
    iframe,
    [tabindex],
    [contentEditable=true] {
        transition: none;
        &:not([tabindex='-1']) {
            &:focus {
            @include accessibility-highlight;
            }
        }
        // Uncomment to preview all links
        //@include accessibility-highlight;
    }
}



$placeholders: (
    '::-webkit-input-placeholder',
    '::-moz-placeholder',
    ':-ms-input-placeholder',
    ':-moz-placeholder',
    '::placeholder'
);

input, textarea {
    @each $placeholder in $placeholders {
        &#{$placeholder} {
            color: $black;
            opacity: .50;
        }
    }
    &:focus {
        @each $placeholder in $placeholders {
            &#{$placeholder} {
                opacity: .75;
            }
        }
    }
}

select {
    margin-bottom: 0;
    width: auto;
}

textarea {
    resize: vertical;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.clearfix {
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

@media print {
    header, footer, aside, embed, object, iframe,
    #admin_bar_primary, .block-video, .type-slideshow {
        display: none !important;
    }
}

a, button, .button {
    transition: all 200ms linear;
    [data-whatintent="touch"] &,
    [data-whatintent="keyboard"] & {
        transition: none;
    }
}

$header-margin-top: .66em !default;
$header-margin-bottom: .33em !default;

h1, h2, h3, h4, h5, h6 {

    margin-bottom: $header-margin-bottom;
    margin-top: $header-margin-top;

    a & {
        transition: all 200ms linear;
    } 

    a {
        color: inherit !important;
        display: block;
        &:hover {
            color: $anchor-color-hover !important;
        }
    }
}

dl {

    dt {
        font-size: 1.1em;
        margin-bottom: .25em;
        margin-top: .66em;
        line-height: 1.25;
    }

    dd {
        padding-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@include breakpoint(large) {
    ul, ol, dl, p {
        font-size: rem-calc(17);
    }
    p.lead {
        font-size: rem-calc(20);
    }
}

//@include breakpoint(xlarge) {
//    ul, ol, dl, p {
//        font-size: rem-calc(18);
//    }
//    p.lead {
//        font-size: rem-calc(21);
//    }
//}

/* Slick Arrows */
.slick-slider {

    .slick-slide {
        position:relative;
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        z-index: 1;
        line-height: 0px;
        cursor: pointer;
        bottom: 0;
        text-align: center;

        line-height: 50px;
        height:50px;
        width: 50px;
        padding:0;

        border: none;
        font-size: 2rem;
        background: $primary-color;

        &:before {
            margin-right: 0;
        }

        &.slick-disabled {
            opacity: 0.33;
            pointer-events: none;
        }
    }
    .slick-prev {
        right: 50px;
    }
    .slick-next {
        background: smart-scale($primary-color, 6%);
        right: 0;
    }
}

.callout {
    p {
        font-size: 1rem;
        line-height: 1.4;
        color: rgba(0,0,0,.65);
        margin-bottom: .66em;
    }
    &.small p {
        font-size: .9rem;
        line-height: 1.3;
        margin-bottom: .4em;
    }
    &.large p {
        font-size: 1.16rem;
        line-height: 1.5;
        margin-bottom: 1em;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

hr {
    &.primary {
        border-bottom-color: $primary-color;
    }
    &.secondary {
        border-bottom-color: $secondary-color;
    }
    &.success {
        border-bottom-color: $success-color;
    }
    &.warning {
        border-bottom-color: $warning-color;
    }
    &.alert {
        border-bottom-color: $alert-color;
    }
}

/* AM Forms defaults */
form.form {

    em,
    .form__note {
        font-style: normal;
        font-size: .8rem;
        opacity: .7;
        line-height: 1.1;
        display: block;
    }

    .form__note {
        margin: 1em 0;
    }

    input[type=checkbox],
    input[type=radio] {
        margin-bottom: 0;
        & + label {
            display: inline;
            color: inherit;
            font-size: 15px;
            line-height: 1.1;
        }
    }

    .type-radiobuttons,
    .type-checkboxes {
        div div {
            margin-bottom: 10px;
        }
    }

}


table.responsive {

    @media (max-width: 1024px) {

        width: 100%;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        border-spacing: 0;
        border: 0;

        thead {
            display: none;
        }

        tr {
            display: block;
            border-bottom: 2px solid #ddd !important;
            &:last-child {
                margin-bottom: 0;
                border-bottom: none !important;
            }
        }

        td {
            display: block;
            text-align: right;
            border-bottom: 1px solid #ddd;

            &:last-child {
                border-bottom: 0;
            }

            &:before {
                content: attr(data-rt-label);
                float: left;
                font-weight: bold;
            }
        }
    }
}

$thin-spacing: 8px;
.thin-grid {
    &.grid-padding-x {
        margin-right: -$thin-spacing/2;
        margin-left: -$thin-spacing/2;
        > .cell {
            padding-left: $thin-spacing/2;
            padding-right: $thin-spacing/2;
        }
    }
    &.grid-padding-y {
        margin-top: -$thin-spacing/2;
        margin-bottom: -$thin-spacing/2;
        > .cell {
            padding-top: $thin-spacing/2;
            padding-bottom: $thin-spacing/2;
        }
    }
}

.svg-icon {
    display: inline-block;
    vertical-align: text-top;
    svg {
        fill: currentColor;
        height: 1.1em;
        width: 1.1em;
        display: block;
    }
}

// More reasonable callout colour. Saturation too out of wack otherwise.
.callout {

    a {
        white-space: nowrap;
    }

    $callout-background: #f6f6f6;
    background-color: $callout-background;
    color: color-pick-contrast($callout-background, ($callout-font-color, $callout-font-color-alt));
    border: 1px solid rgba(#000, 0.2);
    @each $name, $color in $foundation-palette {
        &.#{$name} {
            $callout-background: rgba($color, .15);
            background-color: $callout-background;
            color: color-pick-contrast($callout-background, ($callout-font-color, $callout-font-color-alt));
        }
    }
}