.content-blocks {

    $horizontal-spacing: 20px;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    a.magnify {
        color: #fff;
        font-size: 1.8rem;
        text-align: center;
        width: 40px;
        height: 43px;
        line-height: 40px;
        right: 10px;
        top: 10px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
        position: absolute;
        display: block;
        opacity: .75;
        &:before {
            text-indent: 999px;
        }
        &:hover {
            opacity: 1;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
    }

    &, .block {

        margin-bottom: 15px;
        margin-top: 15px;

        @media (min-width: 1024px) {
            margin-bottom: 22px;
            margin-top: 22px;
        }

        @media (min-width: 1280px) {
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

    .block:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: -15px;

        @media (min-width: 1024px) {
            margin-top: -25px;
        }

        @media (min-width: 1280px) {
            margin-top: -35px;
        }
    }

    .block-text {
        up, ol, p, table {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .block-videoGallery, .block-gallery {

        .thumbnail {
            margin-bottom: 8px;
        }

        .vid-thumb {
            border-radius: 5px;
            transition: 200ms ease opacity;
            background-position: 50% 50%;
            background-size: cover;
            min-height: 130px;
            @include breakpoint(large) {
                min-height: 150px;
            }

            display: flex;
            justify-content: center;
            img {
                opacity: 0.6;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        .type-slideshow {
            position: relative;
            p {
                min-height: 50px;
                position: absolute;
                bottom: 0;
                right: 100px;
                padding: 6px 10px 8px;
                background: rgba(0, 0, 0, .75);
                margin: 0;
                color: #fff;
                font-weight: normal;
                font-size: rem-calc(14);
                line-height: 1.285714286;
            }
        }
    }

    .block-videoGallery,
    .block-gallery,
    .block-slideshow {
        clear: both;
    }

    .block-image {

        .img {
            position: relative;
        }

        p {
            line-height: 1.25;
            font-size: .9rem;
            color: smart-scale($black, 30%);
        }
    }

    .block-map {
        a {
            display: block;
        }
    }

    .block-audioPlayer,
    .block-callout,
    .block-map,
    .block-media,
    .block-image {

        @media (min-width: 641px) {

            clear: both;

            & > div {
                width: 50%;
                margin-bottom: 15px;

                @media (min-width: 1024px) {
                    margin-bottom: 22px;
                }

                @media (min-width: 1280px) {
                    margin-bottom: 30px;
                }

            }

            .position-left {
                float: left;
                padding-right: $horizontal-spacing;
                padding-top: 6px;
            }
            .position-right {
                float: right;
                padding-left: $horizontal-spacing;
                padding-top: 6px;
            }
            .position-center {
                margin-left: auto;
                margin-right: auto;
                min-width: 320px;
            }
            .position-full {
                width: auto;
            }
        }

        & > div {

            @media (min-width: 1024px) {
                width: 45%;
                &.position-center {
                    min-width: 60%;
                }
            }
        }
    }

    .block-bannerImage {

        color: #fff;

        .right-aligned {
            text-align: right;
        }

        p, h2 {
            text-shadow: 1px 0 7px rgba(0, 0, 0, 0.5), 5px 0 40px rgba(0, 0, 0, 0.5);
        }

        p {
            font-size: 1rem;
            line-height: 1.4;
        }

        .button {
            text-shadow: none;
        }

        @each $size in $breakpoint-classes {
            $spacing: map-get($grid-margin-gutters, $size);
            $lower-bound-size: map-get($breakpoints, $size);
            $lower-bound: -zf-bp-to-em($lower-bound-size);
            @media screen and (min-width: $lower-bound) {
                margin-left: -$spacing/2;
                margin-right: -$spacing/2;
            }
        }
    }

}

.twitter-tweet {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.listing {

    &.color-Purple {
        .link,
        .title,
        h2, h2:before {
            background: $mtzwp-purple;
        }
    }
    &.color-Blue {
        .link,
        .title,
        h2, h2:before {
            background: $mtzwp-blue;
        }
    }
    &.color-Ochre {
        .link,
        .title,
        h2, h2:before {
            background: $mtzwp-ochre;
        }
    }
    &.color-Green {
        .link,
        .title,
        h2, h2:before {
            background: $primary-color;
        }
    }

    .header {
        h2 {
            text-align: right;
            padding-top: 159px;
            margin: 0;
            color: #fff;
            padding-right: 15px;
            padding-bottom: 5px;
            position: relative;
            margin-bottom: 8px;
            font-size: 1.5em;
            span {
                position: relative;
            }
            &:before {
                opacity: .75;
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    .link {
        transform: translateY(8px);

        &:before {
            @include breakpoint(small only) {
                padding-top: 25% !important;
            }
        }

        &:after {
            content: 'More';
            position: absolute;
            color: #fff;
            font-size: 1.5em;
            font-weight: bold;
            font-family: $header-font-family;
            left: 50%;
            top: 48%;
            transform: translate(-50%, -50%);
        }
    }

    .choices {

        .cell {
            overflow: hidden;
            margin-bottom: 8px;
        }

        a {
            display: block;
            position: relative;

            &:hover {
                opacity: .8;
            }

            .title {
                position: absolute;
                top: 15px;
                right: 0;
                padding: 3px 10px;
                color: #fff;
            }
            &:before {
                display: block;
                position: relative;
                content: '';
                padding-top: 66.66%;
                @include breakpoint(medium) {
                    padding-top: 100%;
                }
                @include breakpoint(large) {
                    padding-top: 150%;
                }
            }
        }

        .has-link a:before {
            @include breakpoint(medium) {
                padding-top: 50%;
            }
            @include breakpoint(large) {
                padding-top: 74%;
            }
        }

        @include breakpoint(large) {
            .half {
                a:before {
                    padding-top: 74%;
                }
                &.has-link a:before {
                    padding-top: 37%;
                }
            }
        }
    }
}

.entries-list.news {

    > .cell {
        padding-bottom: 20px;
    }

    .img {
        display: block;
        position: relative;
        &:hover {
            opacity: .75;
        }
        &:after {
            content: '';
            display: block;
            position: relative;
            padding-top: 60%;
        }
    }

    h3 {
        font-weight: normal;
        font-size: 1.33rem;
        padding-right: 15px;
    }

    p {
        padding-right: 15px;
        font-size: .9rem;
        line-height: 1.4;
        margin-bottom: .5em;
        &.date {
            font-style: italic;
        }
    }
}