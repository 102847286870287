$nav-link-padding: .5em;

.quicklinks-content{
    p {
       &, & > * {
           vertical-align: middle;
       }
    }
}

nav .quicklinks-content {
    a.button {
        background: $secondary-color;
        &:hover {
            background: $secondary-color;
        }
    }
    .social-media {
        display: block;padding-bottom: 8px;
    }
    p {
        margin-top: -10px;
        margin-bottom: 0;
        padding-left: 10px;
    }
}

header {

    position: relative;
    z-index: 2;

    .logo {
        margin: 0 auto;
        display: block;
        float: left;
        max-width: 200px;
        text-align: center;

        @include breakpoint(large) {
            max-width: none;
        }

        img {
            display: block;
            max-width: 100%;
        }

        &:hover {
            opacity: 0.666;
        }
    }

    .quicklinks {
        float: right;
        text-align: right;
    }

    nav {

        display: none;
        clear: both;
        font-family: $header-font-family;
        letter-spacing: -0.025em;

        @include breakpoint(medium down) {
            padding: 8px 0 9px;
            position: absolute;
            left: 0;
            background: $primary-color;
            right: 0;
            top: 100%;
            z-index: 1;
            font-size: rem-calc(19);

            a {
                color: $white !important;
            }

            .active > a {
                font-weight: bold;
            }

            &.open {
                display: block;
            }
        }

        @include breakpoint(large) {
            display: block;
            clear: right;
        }

        ul {

            margin: 0;
            padding: 0;
            list-style: none;
            line-height: 40px;

            @include breakpoint(large) {
                clear: none;
                float: right;
                line-height: 2.7em;
                font-size: rem-calc(18.2);
                margin-left: -$nav-link-padding;
                li.level-1:last-child a {
                    padding-right: 0;
                }
            }

            @include breakpoint(xlarge) {
                font-size: rem-calc(19.75);
            }

            a {
                text-decoration: none;
                display: block;
                padding: 0 $nav-link-padding;
                color: #444;
            }


            li {

                &.open > ul{
                    display: block;
                }

                @include breakpoint(large) {
                    float: left;
                    &:hover > ul {
                        display: block;
                    }
                }

                &:hover > a {
                    color: $primary-color;
                }

                &.active > a {
                    color: $primary-color;
                    cursor: default;
                }

                @include breakpoint(large) {
                    &.level-1.has-children > a:after {
                        @include css-triangle(5px, currentColor, 'down');
                        display: inline-block;
                        margin-left: 5px;
                        vertical-align: middle;
                        margin-top: -1px;
                    }
                }

                @include breakpoint(medium down) {
                    &.has-children > a:after {
                        @include css-triangle(5px, currentColor, 'down');
                        display: inline-block;
                        margin-left: 2px;
                        vertical-align: middle;
                        margin-top: -2px;
                    }
                }
            }

            ul {
                font-weight: normal;
                padding-left: 20px;
                margin: 0;
                line-height: 32px;
                margin-top: -5px;
                margin-bottom: 7px;
                display: none;

                @include breakpoint(large) {
                    padding-left: 0px;
                    display: none;
                    position: absolute;
                    background: rgba(255, 255, 255, .8);
                    border-top: 5px solid $primary-color;
                    padding: 5px 0 9px;
                    margin-bottom: 0;
                    margin-top: -5px;
                    margin-left: -$nav-link-padding*.666;
                    line-height: 40px;
                    box-shadow: 0 5px 30px rgba(#000, .2);

                    ul {
                        display: none !important;
                    }
                }

                li {
                    float: none;
                }

                a {
                    font-size: .9em;
                    padding: 0 $nav-link-padding*1.333;
                }
            }

        }
    }

    .menu-icon a {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
        white-space: nowrap;
        padding: 8px 15px;
        display: none;
        font-size: 1.25rem;
        font-family: $header-font-family;

        @include breakpoint(medium down) {
            display: block;
        }

        &.open {
            background: $primary-color;
            color: $white;
            span {
                transform: rotate(45deg);
                background: $white;
                &:after {
                    opacity: 0;
                }
                &:before {
                    top: 0;
                    transform: rotate(90deg);
                }
            }
        }

        span {
            display: inline-block;
            background: $primary-color;
            height: 3px;
            width: 26px;
            position: relative;
            margin-left: 10px;
            vertical-align: middle;
            transition: linear 50ms all;

            &:before,
            &:after {
                transition: linear 50ms all;
                position: absolute;
                display: block;
                background: inherit;
                height: 3px;
                width: inherit;
                content: "";
                left: 0;
                top: -10px;
            }

            &:after {
                top: 10px;
            }
        }
    }
}
