.banner-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.banner {
    margin: 0 auto;
    overflow: hidden;
    min-height: 45vh;

    h2 {
        //margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }

    &, .slide {
        background: center center no-repeat;
        background-size: cover;
    }
}


.banner-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.banner {
    overflow: hidden;
    background: center center no-repeat;
    background-size: cover;
    position: relative;
    padding-bottom: 100px;
    padding-top: 100px;

    &:before,
    &:after {
        pointer-events: none;
        left: 0;
        right: 0;
        content: "";
        position: absolute;
        height: 100px;
    }

    &:after {
        top: -1px;
        background: url(../img/cover1.png) repeat-x top center;
    }
    &:before {
        bottom: -1px;
        background: url(../img/cover2.png) repeat-x top center;
        transform: rotate(180deg);
    }
}









